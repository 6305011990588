.account-settings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.account-header {
  text-align: center;
  margin-bottom: 40px;
}

.account-header h1 {
  color: #1f2937;
  font-size: 2.5rem;
}

.account-content {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
}

.profile-section {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 2rem;
}

.profile-photo-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e7eb;
  margin: 0 auto 1.5rem;
  border: 4px solid #e5e7eb;
  transition: border-color 0.3s;
}

.profile-photo-container:hover {
  border-color: #3b82f6;
}

.profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-icon {
  font-size: 64px;
  color: #6b7280;
}

.profile-photo-placeholder i {
  font-size: 4rem;
  color: #6b7280;
}

.profile-info h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 0.5rem;
}

.profile-info .email {
  color: #6b7280;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.profile-info .joined-date {
  color: #9ca3af;
  font-size: 0.875rem;
}

.account-details {
  padding: 40px;
}

.detail-section h3 {
  color: #1f2937;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detail-item label {
  color: #6b7280;
  font-size: 0.9rem;
}

.detail-item span {
  color: #1f2937;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .profile-section {
    padding: 1.5rem;
  }

  .profile-photo-container {
    width: 96px;
    height: 96px;
  }

  .profile-icon {
    font-size: 48px;
  }

  .profile-info h2 {
    font-size: 1.25rem;
  }

  .account-details {
    padding: 30px 20px;
  }

  .detail-grid {
    gap: 20px;
  }
}

.account-settings {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
}

.profile-controls {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.profile-controls h2 {
  color: #2c3e50;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f0f0f0;
}

.settings-group {
  margin-bottom: 40px;
  padding: 25px;
  background: #fafafa;
  border-radius: 8px;
}

.settings-group + .settings-group {
  margin-top: 30px;
}

.settings-group h3 {
  color: #34495e;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.settings-select {
  width: 100%;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  color: #2c3e50;
  transition: border-color 0.3s ease;
}

.settings-select:focus {
  border-color: #3498db;
  outline: none;
}

.subscription-option {
  margin-bottom: 20px;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.subscription-option:hover {
  border-color: #3498db;
  background: #f8f9fa;
}

.subscription-option input[type="radio"] {
  margin-right: 10px;
}

.subscription-label {
  font-weight: 600;
  color: #2c3e50;
  display: block;
  margin-bottom: 8px;
}

.feature-list {
  list-style: none;
  padding-left: 25px;
  margin: 10px 0 0;
}

.feature-list li {
  color: #666;
  margin-bottom: 5px;
  position: relative;
}

.feature-list li:before {
  content: "✓";
  color: #27ae60;
  position: absolute;
  left: -20px;
}

@media (max-width: 768px) {
  .account-settings {
    padding: 20px;
  }
}

.update-button-container {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
  text-align: right;
}

.update-button {
  background: #3498db;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.update-button:hover {
  background: #2980b9;
}

.update-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.3);
}

.update-button.updating {
  background: #bdc3c7;
  cursor: not-allowed;
  opacity: 0.8;
}

.update-button:disabled {
  cursor: not-allowed;
}

.loading-state {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 1.1rem;
}

.current-role,
.current-subscription {
  margin-bottom: 12px;
  padding: 8px 12px;
  background: #f1f8ff;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #2c3e50;
}

.highlight {
  font-weight: 600;
  color: #3498db;
}

.settings-select {
  margin-top: 10px;
} 