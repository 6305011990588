/* Container */
.event-actions {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 28px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.event-actions h3 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #2c3e50;
  margin: 0 0 20px 0;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(44, 62, 80, 0.1);
}

/* Button Container */
.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 32px;
}

/* Base Button Styles */
.action-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  min-width: 140px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease;
  position: relative;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.action-button i {
  font-size: 1rem;
}

.action-button.archive i { color: #3498db; }
.action-button.unarchive i { color: #16a34a; }
.action-button.edit i { color: #2563eb; }

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.action-button .fa-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Loading state text styles */
.action-button:disabled .button-label {
  color: #94a3b8;  /* Lighter text when loading */
}

/* Button Variants */
.action-button.archive {
  color: #3498db;
  border-color: #3498db;
}

.action-button.visibility {
  position: relative;
}

.action-button.visibility.public {
  color: #e67e22;
  border-color: #e67e22;
}

.action-button.visibility.private {
  color: #3498db;
  border-color: #3498db;
}

.action-button.digital {
  color: #7c3aed;
  border-color: #7c3aed;
}

.action-button.physical {
  color: #f39c12;
  border-color: #f39c12;
}

.action-button.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #334155;
  border-color: #e2e8f0;
  background-color: #ffffff;
}

.action-button.edit i { 
  color: #334155;
}

.action-button.unarchive {
  color: #16a34a;
  border-color: #27ae60;
}

.action-button.unarchive:hover {
  background-color: #f0fdf4;
  border-color: #bbf7d0;
}

.action-button.unarchive i {
  color: #059669;
}

/* Hover States */
.action-button.archive:hover:not(:disabled) {
  color: #2563eb;
  background: #eff6ff;
  border-color: #bfdbfe;
}

.action-button.visibility:hover:not(:disabled) {
  background: #bae6fd;
}

.action-button.digital:hover:not(:disabled) {
  color: #6d28d9;
  background: #f5f3ff;
  border-color: #ddd6fe;
}

.action-button.physical:hover:not(:disabled) {
  background: #e9d5ff;
}

.action-button.edit:hover:not(:disabled) {
  color: #1e293b;
  background: #f8fafc;
  border-color: #cbd5e1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.action-button.visibility:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background: #1f2937;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
}

/* Status Messages */
.error-message {
  background-color: #fee2e2;
  color: #b91c1c;
  border: 1px solid #fecaca;
  margin-top: 20px;
  padding: 14px;
  border-radius: 10px;
  font-size: 0.9375rem;
  text-align: center;
  font-weight: 500;
}

.loading-message {
  background-color: #e2e8f0;
  color: #475569;
  border: 1px solid #cbd5e1;
  margin-top: 20px;
  padding: 14px;
  border-radius: 10px;
  font-size: 0.9375rem;
  text-align: center;
  font-weight: 500;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .event-actions {
    padding: 24px;
  }

  .event-actions h3 {
    font-size: 1.125rem;
    margin-bottom: 16px;
  }

  .action-buttons {
    gap: 16px;
    margin-bottom: 28px;
  }

  .action-button {
    padding: 10px 16px;
    font-size: 0.875rem;
  }

  .memorialize-heading {
    margin-top: 28px;
  }
}

@media (max-width: 480px) {
  .event-actions {
    padding: 20px;
  }

  .action-buttons {
    flex-direction: column;
    gap: 12px;
  }

  .action-button {
    width: 100%;
  }

  .error-message,
  .loading-message {
    font-size: 0.875rem;
    padding: 12px;
  }
}

.action-button.visibility.public {
  color: #e67e22;
  border-color: #e67e22;
}

.action-button.visibility.private {
  color: #3498db;
  border-color: #3498db;
}

.action-button.visibility.public:hover {
  background-color: #e67e22;
}

.action-button.visibility.private:hover {
  background-color: #3498db;
}

.action-button.visibility:hover {
  opacity: 0.9;
}

.action-button.visibility:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.memorialize-heading {
  margin-top: 32px;
}

.action-button i {
  font-size: 1rem;
}

.event-management-controls {
  background: #f8fafc;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid #e2e8f0;
  margin: 24px 0;
}

.management-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.management-header i {
  color: #3b82f6;
  font-size: 1rem;
}

.management-label {
  color: #334155;
  font-size: 0.875rem;
  font-weight: 600;
}

.owner-badge {
  margin-left: auto;
  background: #dbeafe;
  color: #2563eb;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 12px;
}

.management-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px 0;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 110px;
  padding: 10px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover:not(:disabled) {
  background: #f1f5f9;
  border-color: #cbd5e1;
}

.action-button.archive:hover:not(:disabled) {
  color: #2563eb;
  background: #eff6ff;
  border-color: #bfdbfe;
}

.action-button.edit:hover:not(:disabled) {
  color: #2563eb;
  background: #eff6ff;
  border-color: #bfdbfe;
}

.action-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-label {
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .event-management-controls {
    padding: 12px;
    margin: 32px 0;
  }

  .management-header {
    margin-bottom: 12px;
    padding-bottom: 8px;
  }

  .button-label {
    display: none;
  }

  .action-button {
    padding: 8px;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .management-buttons {
    gap: 16px;
    justify-content: space-between;
    padding: 0 8px;
  }

  .action-button {
    min-width: unset;
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 8px;
  }

  .button-label {
    display: none;
  }

  .action-button i {
    margin: auto;
    font-size: 1.125rem;
  }
}

/* Smaller screen adjustments */
@media (max-width: 360px) {
  .management-buttons {
    gap: 12px;
    padding: 0 4px;
  }

  .action-button {
    width: 44px;
    height: 44px;
    padding: 10px;
  }

  .action-button i {
    font-size: 1rem;
  }
}

/* Section specific spacing */
.event-management-controls + .notes-section {
  margin-top: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .event-management-controls {
    margin: 32px 0;
  }
}

@media (max-width: 480px) {
  .event-management-controls {
    margin: 24px 0;
  }
}

.button-label {
  white-space: nowrap;
}

.confirm-dialog {
  padding: 24px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.confirm-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.confirm-dialog h2 {
  margin: 0 0 16px 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.confirm-dialog p {
  margin: 0 0 24px 0;
  color: #4b5563;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.button-secondary {
  padding: 8px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  color: #4b5563;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.button-warning {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #dc2626;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.button-primary {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #2563eb;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}