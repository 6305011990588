.digital-product__photo {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background: #f8f9fa;
}

.digital-product__photo-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
}

.digital-product__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.digital-product__photo:hover .digital-product__image {
  transform: scale(1.05);
}

.digital-product__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  justify-content: flex-end;
}

.digital-product__placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: #f8f9fa;
  color: #666;
}

.digital-product__placeholder.error {
  color: #dc2626;
}

.digital-product__placeholder i {
  font-size: 2rem;
}

.digital-product__tag {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
} 