.save-dialog {
  position: fixed;
  border: none;
  padding: 0;
  margin: auto;
  width: 95%;
  max-width: 400px;
  background: transparent;
  inset: 0;
  z-index: 1000;
}

.save-dialog::backdrop {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
}

.save-dialog[open] {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: dialogFadeIn 0.3s ease-out;
}

.save-content {
  background: #ffffff;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  width: 100%;
  animation: contentSlideUp 0.3s ease-out;
}

.save-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0 0 16px 0;
}

.save-message {
  color: #4b5563;
  font-size: 1rem;
  margin: 0 0 24px 0;
}

.save-actions {
  display: flex;
  gap: 12px;
}

.save-button {
  flex: 1;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.save-button.primary {
  background: #2563eb;
  color: white;
  border: none;
}

.save-button.secondary {
  background: white;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.save-button:hover {
  transform: translateY(-1px);
}

.save-button.primary:hover {
  background: #1d4ed8;
}

.save-button.secondary:hover {
  background: #f8fafc;
  border-color: #d1d5db;
} 