.event-header {
  width: 100%;
  background: linear-gradient(120deg, #ffffff, #f8f9fa);
  padding: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);
}

.header-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.title-wrapper {
  flex: 1;
  min-width: 0;
}

.title-section {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.icon-wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #fff6e6, #fffbf2);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.2);
  padding-top: 4px;
}

.trophy-icon {
  color: #ffd700;
  font-size: 1.75rem;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  transform: translateY(-2px);
}

.event-title {
  margin: 0;
  font-size: 1.875rem;
  font-weight: 700;
  color: #2c3e50;
  letter-spacing: -0.02em;
  line-height: 1.3;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #64748b;
  background: #f8fafc;
  border: 0px solid #e2e8f0;
}

.status-badge.archived {
  background-color: #f0f0f0;
  color: #666666;
}

.status-badge i {
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .event-header {
    padding: 24px;
  }

  .header-content {
    gap: 24px;
  }

  .icon-wrapper {
    width: 40px;
    height: 40px;
  }

  .trophy-icon {
    font-size: 1.5rem;
  }

  .event-title {
    font-size: 1.5rem;
  }

  .status-badge {
    padding: 4px 10px;
    font-size: 0.8125rem;
  }

  .status-badge i {
    font-size: 0.8125rem;
  }
}

@media (max-width: 480px) {
  .event-header {
    padding: 20px;
  }

  .header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .title-section {
    gap: 16px;
  }

  .icon-wrapper {
    width: 36px;
    height: 36px;
  }

  .trophy-icon {
    font-size: 1.25rem;
  }

  .event-title {
    font-size: 1.25rem;
  }

  .status-section {
    width: 100%;
  }

  .status-badge {
    width: 100%;
    justify-content: center;
    padding: 10px 16px;
    font-size: 0.8125rem;
  }
} 