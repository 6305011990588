.admin-action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
}

.action-button {
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease;
}

.action-button:hover {
  opacity: 0.9;
}

.action-button.view {
  background: #4299e1;
}

.action-button.visibility {
  background: #48bb78;
}

.action-button.delete {
  background: #f56565;
}

.action-button i {
  font-size: 0.9rem;
}

.template-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: #ebf8ff;
  color: #2b6cb0;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.template-badge i {
  font-size: 0.75rem;
}

/* Optional: Style the row differently if it's a template */
tr:has(.template-badge) {
  background-color: #f8fafc;
}

tr:has(.template-badge):hover {
  background-color: #f0f5fa;
} 