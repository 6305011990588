/* Main container */
.event-preview {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  padding: 32px 24px;
}

.event-preview-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Frame container */
.champion-frame.image-frame {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 32px;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Content wrapper */
.champion-content {
  width: 100%;
  padding: 24px;
}

/* Image container */
.event-image-container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 16px;
}

/* Large screens */
@media (min-width: 1441px) {
  .event-preview {
    padding: 48px 32px;
  }
}

/* Desktop */
@media (max-width: 1200px) {
  .event-preview-content {
    max-width: 960px;
  }
}

/* Tablet */
@media (max-width: 992px) {
  .event-preview {
    padding: 24px 20px;
  }

  .event-preview-content {
    max-width: 800px;
  }
}

/* Small tablet */
@media (max-width: 768px) {
  .event-preview {
    padding: 20px 16px;
  }

  .champion-frame.image-frame {
    margin: 0 auto 24px;
    border-radius: 12px;
  }

  .champion-content {
    padding: 16px;
  }

  .event-image-container {
    padding: 12px;
  }
}

/* Mobile */
@media (max-width: 480px) {
  .event-preview {
    padding: 16px 12px;
  }

  .champion-frame.image-frame {
    margin: 0 auto 20px;
    border-radius: 8px;
  }

  .champion-content {
    padding: 12px;
  }

  .event-image-container {
    padding: 8px;
  }
}

/* Small mobile */
@media (max-width: 360px) {
  .event-preview {
    padding: 12px 8px;
  }

  .champion-content {
    padding: 8px;
  }

  .event-image-container {
    padding: 4px;
  }
}

.guest-banner-wrapper {
  margin: 32px 0;
  padding: 24px 0;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}