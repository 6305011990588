.ambassadors-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.ambassadors-hero {
  text-align: center;
  padding: 6rem 0;
  background: linear-gradient(to right, #3498db, #2980b9);
  color: white;
  margin: 0 -2rem;
}

.ambassadors-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.hero-subtitle {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: 0 auto;
}

.join-ambassador {
  text-align: center;
  padding: 6rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  border-radius: 16px;
  margin-top: 4rem;
}

.join-ambassador h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.join-ambassador p {
  color: #666;
  max-width: 600px;
  margin: 0 auto 2rem;
}

.cta-button {
  background: linear-gradient(to right, #3498db, #2980b9);
  color: white;
  padding: 1.2rem 2.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(52, 152, 219, 0.4);
}

@media (max-width: 768px) {
  .ambassadors-hero h1 {
    font-size: 2rem;
  }

  .ambassadors-container {
    padding: 1rem;
  }
}
