.hero {
  position: relative;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
  animation: zoomIn 20s ease-out forwards;
}

.hero-vignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.hero-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.hero-text-container {
  text-align: center;
  color: white;
  max-width: 800px;
  animation: fadeInUp 1s ease-out;
}

.hero-title {
  margin-bottom: 30px;
}

.hero-title-main {
  display: block;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: slideInDown 1s ease-out;
}

.hero-title-accent {
  display: block;
  font-size: 2rem;
  font-weight: 300;
  color: #7cc6fe;
  margin-top: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  animation: slideInUp 1s ease-out 0.3s both;
}

.hero-tagline {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-out 0.6s both;
}

.hero-tagline-text {
  display: block;
}

.hero-tagline-highlight {
  color: #7cc6fe;
  font-weight: 600;
}

.hero-features {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;
  animation: fadeIn 1s ease-out 0.9s both;
}

.hero-feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.hero-feature-icon {
  font-size: 2rem;
  color: #7cc6fe;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.hero-feature-text {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.hero-brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.hero-logo__image {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.hero-logo__fallback {
  font-size: 2rem;
  color: #333;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-background--fallback {
  background: linear-gradient(135deg, #2c3e50 0%, #3498db 100%);
  min-height: 100%;
  width: 100%;
}

/* Animations */
@keyframes zoomIn {
  from { transform: scale(1.1); }
  to { transform: scale(1); }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title-main {
    font-size: 3rem;
  }
  
  .hero-title-accent {
    font-size: 1.5rem;
  }
  
  .hero-tagline {
    font-size: 1.2rem;
  }
  
  .hero-features {
    gap: 20px;
  }
  
  .hero-feature-icon {
    font-size: 1.5rem;
  }
  
  .hero-feature-text {
    font-size: 0.9rem;
  }
  .hero-logo__image {
    width: 48px;
    height: 48px;
  }
  .hero-brand {
    gap: 0.75rem;
    margin-bottom: 0.375rem;
  }
}

@media (max-width: 480px) {
  .hero-title-main {
    font-size: 2.5rem;
  }
  
  .hero-features {
    flex-direction: column;
    gap: 15px;
  }
  .hero-logo__image {
    width: 40px;
    height: 40px;
  }
  .hero-brand {
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }
} 