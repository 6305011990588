/* Main container */
.notes-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.notes-input-section {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .notes-container {
    gap: 16px;
  }

  .notes-input-section {
    padding: 16px;
  }
}

/* Note Form */
.note-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

/* Quill Editor Overrides */
.note-editor {
  border-radius: 8px;
  background: #fff;
}

.note-editor .ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-color: #e2e8f0;
  background: #f8fafc;
}

.note-editor .ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: #e2e8f0;
  font-size: 0.875rem;
  min-height: 100px;
}

.note-form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.note-submit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.15s ease;
}

.note-submit-button:hover:not(:disabled) {
  background: #2563eb;
}

.note-submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.note-submit-button i {
  font-size: 0.875rem;
}

.character-count {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 8px;
}

/* Notes List Section */
.notes-section {
  background: #ffffff;
  border-radius: 12px;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.notes-header {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #64748b;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
  font-weight: 500;
}

.notes-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Individual Note Item */
.note-item {
  background: #f8fafc;
  border-radius: 8px;
  padding: 12px;
  border-left: 3px solid #3b82f6;
}

.note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.author-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.anonymous-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
}

.meta-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.note-date {
  color: #6b7280;
  font-size: 0.875rem;
  font-weight: 500;
}

.delete-note-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #ef4444;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.delete-note-button:hover {
  background: #fee2e2;
  color: #dc2626;
  transform: scale(1.05);
}

.delete-note-button i {
  font-size: 0.9rem;
}

.note-input-container {
  margin-top: 16px;
}

.note-input {
  width: 100%;
  min-height: 80px;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  resize: vertical;
  font-size: 0.875rem;
  line-height: 1.5;
}

.note-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.note-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.note-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.note-button.primary {
  background: #3b82f6;
  color: white;
}

.note-button.primary:hover {
  background: #2563eb;
}

.note-button.secondary {
  background: #f1f5f9;
  color: #64748b;
}

.note-button.secondary:hover {
  background: #e2e8f0;
}

/* Mobile optimizations */
@media (max-width: 640px) {
  .notes-section {
    padding: 12px;
    margin: 12px 0;
  }

  .note-item {
    padding: 10px;
  }

  .note-input {
    min-height: 60px;
  }

  .note-actions {
    flex-direction: column;
  }

  .note-button {
    width: 100%;
    padding: 10px;
  }
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .note-form {
    gap: 8px;
  }

  .note-editor .ql-container {
    min-height: 80px;
  }

  .note-form-footer {
    padding: 12px 16px;
  }

  .note-submit-button {
    padding: 8px 12px;
    font-size: 0.8125rem;
  }

  .character-count {
    font-size: 0.8125rem;
  }
}