.footer {
  width: 100%;
  padding: 40px max(40px, calc((100% - 1200px) / 2));
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}

.company-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.company-brand {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.company-logo__image {
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.company-name {
  color: #2c3e50;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.copyright {
  color: #6b7280;
  font-size: 0.875rem;
  margin: 0;
}

.footer-center {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.footer-links {
  display: flex;
  gap: 32px;
}

.footer-link {
  color: #4b5563;
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.footer-link::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background: #7cc6fe;
  transition: width 0.3s ease;
}

.footer-link:hover {
  color: #7cc6fe;
}

.footer-link:hover::after {
  width: 100%;
}

.social-links {
  display: flex;
  gap: 20px;
}

.social-link {
  color: #4b5563;
  font-size: 1.25rem;
  transition: all 0.3s ease;
}

.social-link:hover {
  color: #7cc6fe;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 0;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    padding: 0 20px;
  }

  .company-info {
    align-items: center;
  }

  .footer-links {
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .footer-links {
    gap: 16px;
  }

  .company-name {
    font-size: 1.125rem;
  }

  .copyright {
    font-size: 0.8125rem;
  }

  .footer-link {
    font-size: 0.8125rem;
  }

  .company-logo__image {
    width: 32px;
    height: 32px;
  }
}

.footer-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
}

.footer-right .footer-links {
  display: flex;
  gap: 24px;
}

.company-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.company-link:hover {
  color: #7cc6fe;
}

@media (max-width: 768px) {
  .footer-right {
    align-items: center;
  }
  
  .social-links {
    margin-top: 10px;
  }
} 