.save-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.875rem;
  animation: fadeIn 0.3s ease-out;
}

.save-status.saving {
  background-color: #e3f2fd;
  color: #1976d2;
}

.save-status.saved {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.save-status.error {
  background-color: #ffebee;
  color: #c62828;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
} 