.notes-section {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.notes-header {
  padding: 16px 20px;
  background: #f8fafc;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon {
  color: #3b82f6;
  font-size: 1.25rem;
}

.notes-header h4 {
  color: #1e293b;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.notes-count {
  background: #e0e7ff;
  color: #3b82f6;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
}

.header-divider {
  height: 1px;
  background: #e2e8f0;
  margin-top: 12px;
}

.notes-list {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 500px;
  overflow-y: auto;
}

.notes-list::-webkit-scrollbar {
  width: 6px;
}

.notes-list::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.notes-list::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.notes-list::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

@media (max-width: 640px) {
  .notes-section {
    border-radius: 8px;
  }

  .notes-header {
    padding: 12px 16px;
  }

  .notes-list {
    padding: 12px;
    gap: 10px;
  }
}

.note-item {
  width: 100%;
  background: #f8fafc;
  border-radius: 8px;
  padding: 16px;
  border-left: 3px solid #3b82f6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.note-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.meta-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.note-content {
  width: 100%;
  margin-top: 8px;
  line-height: 1.5;
  word-break: break-word;
}

/* Ensure images and avatars don't break the layout */
.author-photo, .anonymous-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.anonymous-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e2e8f0;
  color: #64748b;
} 