.top-impact {
  padding: 2rem 0;
  margin: 0 0 3rem;
  background-color: transparent;
}

.impact-stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 3rem;
  max-width: 1000px;
  margin: 2rem auto;
  padding: 3rem 0;
  position: relative;
}

.stat-item {
  text-align: center;
  flex: 1;
  min-width: 200px;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-item h3 {
  font-size: 3rem;
  color: #3498db;
  margin-bottom: 0.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, #3498db, #2980b9);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-item p {
  color: #2c3e50;
  font-size: 1.2rem;
  font-weight: 500;
}

.mission-statement {
  text-align: center;
  margin: 3rem auto;
  padding: 2.5rem;
  background: linear-gradient(135deg, #ffffff, #f8f9fa);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  max-width: 800px;
  border: 1px solid rgba(52, 152, 219, 0.1);
}

.mission-statement p {
  color: #2c3e50;
  font-size: 1.3rem;
  line-height: 1.7;
  font-weight: 400;
}

.impact-hero {
  /* width: 100%; */
  height: 60vh;
  max-height: 500px;
  overflow: hidden;
  position: relative;
  margin: 0 -2rem;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.impact-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 30%;
  transition: transform 0.5s ease;
  filter: brightness(0.9);
}

.impact-hero:hover img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .impact-stats {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .stat-item {
    width: 100%;
    max-width: 300px;
  }

  .mission-statement {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  .mission-statement p {
    font-size: 1.1rem;
  }

  .impact-hero {
    height: 40vh;
    min-height: 250px;
    border-radius: 0 0 16px 16px;
  }
} 