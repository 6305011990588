.runner-section {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.runner-form {
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

.form-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
  line-height: 1.2;
}

.title-prefix {
  display: block;
  color: #666;
  font-size: 1.5rem;
}

.title-highlight,
.event-name {
  display: block;
  color: #007bff;
  font-weight: 600;
  margin-top: 5px;
}

.form-subtitle {
  color: #666;
  font-size: 1.1rem;
  margin-top: 10px;
}

.runner-input-form {
  margin-top: 25px;
}

.form-group {
  margin-bottom: 25px;
}

.input-wrapper {
  position: relative;
  margin-bottom: 12px;
  
  &:focus-within {
    filter: drop-shadow(0 2px 4px rgba(0, 123, 255, 0.1));
  }
}

.input-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #007bff;
  font-size: 1.2rem;
  pointer-events: none;
  transition: color 0.2s ease;
  
  .input-wrapper:focus-within & {
    color: #0056b3;
    transform: translateY(-50%) scale(1.1);
  }
}

.runner-input {
  width: 100%;
  padding: 16px 16px 16px 48px;
  border: 2px solid #dee2e6;
  border-radius: 12px;
  font-size: 1.1rem;
  line-height: 1.5;
  transition: all 0.2s ease;
  background-color: #fff;
  
  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
  }
  
  &::placeholder {
    color: #adb5bd;
    transition: color 0.2s ease;
  }
  
  &:focus::placeholder {
    color: #6c757d;
  }
  
  &:hover:not(:focus) {
    border-color: #adb5bd;
  }
}

.input-helper {
  display: block;
  text-align: center;
  color: #666;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.runner-input.error {
  border-color: #dc3545;
  
  &:focus {
    box-shadow: 0 0 0 4px rgba(220, 53, 69, 0.1);
  }
  
  + .input-icon {
    color: #dc3545;
  }
}

.runner-input.success {
  border-color: #28a745;
  
  &:focus {
    box-shadow: 0 0 0 4px rgba(40, 167, 69, 0.1);
  }
  
  + .input-icon {
    color: #28a745;
  }
}

.runner-input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  
  + .input-icon {
    opacity: 0.5;
  }
}

.start-button {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
  
  &:hover {
    background: #0056b3;
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(1px);
  }
}

.startflow-template {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
}

.startflow-template__header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.startflow-template__title {
  color: #333;
  font-size: 1.2rem;
  margin: 0;
}

.startflow-template__icon {
  color: #007bff;
  font-size: 1.2rem;
}

.startflow-template__content {
  color: #666;
}

.startflow-template__location {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.startflow-template__location i {
  color: #007bff;
}

.startflow-template__info {
  background: linear-gradient(to right, #eef2ff, #f5f3ff);
  border: 1px solid #e0e7ff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  transition: box-shadow 0.2s ease;
}

.startflow-template__info:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.startflow-template__info-icon {
  color: #6366f1;
  font-size: 1.2rem;
}

.startflow-template__info-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.startflow-template__info-title {
  color: #4338ca;
  font-weight: 500;
}

.startflow-template__info-text {
  color: rgba(79, 70, 229, 0.9);
  line-height: 1.4;
}

@media (max-width: 480px) {
  .form-title {
    font-size: 1.8rem;
  }
  
  .runner-form {
    padding: 20px;
  }
  
  .runner-input {
    font-size: 1rem;
    padding: 14px 14px 14px 44px;
  }
  
  .input-icon {
    font-size: 1.1rem;
    left: 14px;
  }
  
  .input-helper {
    font-size: 0.85rem;
    margin-left: 14px;
  }
  
  .startflow-template {
    padding: 16px;
  }
  
  .startflow-template__info {
    padding: 12px;
  }
  
  .startflow-template__info-text {
    font-size: 0.9rem;
  }
}
  
@media (forced-colors: active) {
  .runner-input {
    border: 2px solid ButtonText;
  }
  
  .runner-input:focus {
    outline: 2px solid Highlight;
  }
}
  
.startflow-security {
  margin-top: 2rem;
  padding: 1rem;
}

.startflow-security__content {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  background: rgba(236, 253, 245, 0.8);
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #10B981;
}

.startflow-security__icon {
  color: #059669;
  font-size: 1.25rem;
  flex-shrink: 0;
}

.startflow-security__message {
  color: #065F46;
  font-size: 0.875rem;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.startflow-security__detail {
  display: block;
  color: #047857;
  font-size: 0.75rem;
}

@media (max-width: 480px) {
  .startflow-security__content {
    padding: 0.75rem;
    gap: 0.75rem;
  }
  
  .startflow-security__message {
    font-size: 0.8125rem;
  }
}
  