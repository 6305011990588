.edit-event-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.event-header {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px;
  background: linear-gradient(to right, #f8f9fa, #ffffff, #f8f9fa);
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.photo-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  background: #f8f9fa;
}

.image-preview {
  position: relative;
  aspect-ratio: 16/9;
}

.event-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-preview:hover .photo-overlay {
  opacity: 1;
}

.photo-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: white;
  cursor: pointer;
  padding: 15px 30px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
}

.hidden-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.form-section {
  background: white;
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.section-title {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.section-title i {
  color: #007bff;
}

.edit-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.button-group button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-group button[type="submit"] {
  background: #3b82f6;
  color: white;
}

.button-group button[type="button"] {
  background: #6b7280;
  color: white;
}

.button-group button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.photo-section {
  text-align: center;
}

.upload-area {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 20px;
  border: 2px dashed #dee2e6;
  border-radius: 12px;
  padding: 40px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #f8f9fa;
  
  &:hover, &.drag-over {
    border-color: #007bff;
    background: #f0f7ff;
  }
  
  &:focus-visible {
    outline: 2px solid #007bff;
    outline-offset: 2px;
  }
}

.image-preview {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;
}

.event-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-preview:hover {
  .preview-overlay {
    opacity: 1;
  }
  
  .event-photo {
    transform: scale(1.05);
  }
}

.upload-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.upload-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #e7f1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  
  i {
    font-size: 2.5rem;
    color: #007bff;
  }
}

.upload-instructions {
  color: #666;
  margin: 10px 0;
}

.file-limit {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  color: #666;
  
  i {
    color: #007bff;
  }
}

.helper-section {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.helper-text {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  margin: 8px 0;
  justify-content: center;
  
  i {
    color: #007bff;
  }
}

.checkbox-group {
  margin: 20px 0;
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 8px;
}

.custom-checkbox {
  position: relative;
  min-width: 20px;
  height: 20px;
  margin-top: 2px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #dee2e6;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.custom-checkbox:hover input ~ .checkmark {
  border-color: #007bff;
  background-color: #f8f9fa;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #007bff;
  border-color: #007bff;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox input:focus ~ .checkmark {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}

.label-text {
  font-size: 1rem;
  color: #333;
  padding-top: 2px;
}

.checkbox-group .helper-text {
  margin-left: 32px;
  color: #666;
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 6px;
}

.checkbox-group .helper-text i {
  color: #007bff;
  margin-top: 3px;
  flex-shrink: 0;
}
 