.event-timing-display {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 100px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(8px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.timing-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-timing-display.upcoming .timing-icon {
  color: #4CAF50;
  animation: pulse 2s infinite ease-in-out;
}

.event-timing-display.past .timing-icon {
  color: #9e9e9e;
}

.countdown-text {
  font-weight: 600;
  font-size: 0.875rem;
  color: #2c3e50;
  letter-spacing: 0.01em;
  white-space: nowrap;
}

.event-timing-display.upcoming .countdown-text {
  color: #2e7d32;
}

.event-timing-display.past .countdown-text {
  color: #616161;
}

/* Hover effect */
.event-timing-display:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Pulse animation for upcoming events */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .event-timing-display {
    padding: 6px 14px;
    gap: 8px;
  }

  .timing-icon {
    width: 22px;
    height: 22px;
  }

  .countdown-text {
    font-size: 0.8125rem;
  }
}

@media (max-width: 480px) {
  .event-timing-display {
    padding: 6px 12px;
    gap: 6px;
  }

  .timing-icon {
    width: 20px;
    height: 20px;
  }

  .countdown-text {
    font-size: 0.75rem;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .event-timing-display {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  }

  .timing-icon {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  }

  .countdown-text {
    color: #e0e0e0;
  }

  .event-timing-display.upcoming .countdown-text {
    color: #81c784;
  }

  .event-timing-display.past .countdown-text {
    color: #9e9e9e;
  }
} 