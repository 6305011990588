.digital-product {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.digital-product-wrapper {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.digital-product-content {
  padding: 30px;
}

.product-header {
  text-align: center;
  margin-bottom: 30px;
}

.product-header h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2.2rem;
  color: #1a1a1a;
  margin-bottom: 15px;
  font-weight: 700;
}

.preview-section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 20px;
  font-weight: 600;
}

.preview-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.digital-product__photo-wrapper {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.options-section {
  margin-bottom: 40px;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
}

.purchase-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.purchase-button {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 14px 28px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.purchase-button:hover:not(.disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  background: linear-gradient(135deg, #0056b3, #004085);
}

.purchase-button i {
  font-size: 1.2rem;
}

.purchase-button.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: linear-gradient(135deg, #6c757d, #495057);
}

.cancel-link {
  color: #666;
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.2s ease;
}

.cancel-link:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .digital-product {
    padding: 15px;
  }

  .digital-product-content {
    padding: 20px;
  }

  .product-header h2 {
    font-size: 1.8rem;
  }

  .section-title {
    font-size: 1.3rem;
  }

  .checkbox-group {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .digital-product-content {
    padding: 15px;
  }

  .product-header h2 {
    font-size: 1.5rem;
  }

  .section-title {
    font-size: 1.2rem;
  }
} 