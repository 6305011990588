.share-dialog {
  position: fixed;
  border: none;
  padding: 0;
  margin: auto;
  width: 95%;
  max-width: 480px;
  background: transparent;
  inset: 0;
  z-index: 1000;
}

.share-dialog::backdrop {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
}

.share-dialog[open] {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: dialogFadeIn 0.3s ease-out;
}

.share-content {
  background: #ffffff;
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  width: 100%;
  animation: contentSlideUp 0.3s ease-out;
}

.share-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.share-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.share-url-container {
  background: #f9fafb;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 24px;
  display: flex;
  gap: 12px;
}

.share-url-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.925rem;
  color: #374151;
  background: white;
}

.copy-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.copy-button:hover {
  background: #1d4ed8;
  transform: translateY(-1px);
}

.copy-button.copied {
  background: #059669;
}

.share-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin: 0 auto 24px;
  max-width: 400px;
}

.share-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  background: white;
  color: #4b5563;
  transition: all 0.15s ease;
  cursor: pointer;
}

.share-button:hover {
  background: #f8fafc;
  transform: translateY(-1px);
  border-color: #d1d5db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.share-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.share-button i {
  font-size: 1.5rem;
  margin-bottom: 4px;
}

.share-button.facebook { color: #1877f2; }
.share-button.twitter { color: #1da1f2; }
.share-button.linkedin { color: #0a66c2; }
.share-button.whatsapp { color: #25d366; }
.share-button.email { color: #ea4335; }

.share-footer {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #e5e7eb;
}

.share-note {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6b7280;
  font-size: 0.875rem;
  margin: 0;
}

@keyframes dialogFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes contentSlideUp {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .share-dialog {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .share-content {
    padding: 24px 20px;
    border-radius: 24px 24px 0 0;
    margin-top: auto;
  }
  
  .share-options {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin: 20px auto;
    max-width: 320px;
    padding: 0;
  }
  
  .share-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 8px;
    gap: 6px;
  }
  
  .share-button i {
    font-size: 1.25rem;
    margin-bottom: 2px;
  }
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 8px;
  background: #f3f4f6;
  color: #6b7280;
  transition: all 0.2s ease;
}

.close-button:hover {
  background: #e5e7eb;
  color: #374151;
}

.close-button i {
  font-size: 1rem;
} 