.user-events-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.user-events-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.user-events-header h1 {
  color: #1f2937;
  font-size: 2.5rem;
}

.new-event-button {
  padding: 12px 24px;
  background: #10b981;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.new-event-button:hover {
  background: #059669;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.event-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.event-card:hover {
  transform: translateY(-5px);
}

.event-image {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background: #f8f9fa; /* Light background while loading */
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.event-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-image img:hover {
  transform: scale(1.05);
}

/* Optional loading skeleton */
.event-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #f0f0f0 0%,
    #f8f8f8 50%,
    #f0f0f0 100%
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.event-image img[src] {
  opacity: 1;
}

.event-image img[src] + ::before {
  display: none;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.event-info {
  padding: 20px;
}

.event-info h3 {
  color: #1f2937;
  margin-bottom: 10px;
}

.event-info p {
  color: #6b7280;
  margin-bottom: 5px;
}

.event-date {
  font-size: 0.9rem;
  color: #9ca3af;
}

.view-event-button {
  width: 100%;
  padding: 12px;
  background: #3b82f6;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-event-button:hover {
  background: #2563eb;
}

.no-events {
  grid-column: 1 / -1;
  text-align: center;
  padding: 80px 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.no-events p {
  font-size: 1.2rem;
  color: #6b7280;
  margin-bottom: 30px;
}

.create-first-button {
  margin-top: 20px;
  padding: 12px 24px;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.create-first-button:hover {
  background: #2563eb;
}

@media (max-width: 768px) {
  .user-events-header {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .user-events-header h1 {
    font-size: 2rem;
  }
}

.event-image {
  position: relative;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(239, 68, 68, 0.9);
  color: white;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
}

.event-card:hover .delete-button {
  opacity: 1;
}

.delete-button:hover {
  background: rgb(239, 68, 68);
  transform: scale(1.1);
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.delete-modal-content h2 {
  color: #1f2937;
  margin-bottom: 15px;
}

.delete-modal-content p {
  color: #6b7280;
  margin-bottom: 25px;
}

.delete-modal-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.cancel-button,
.confirm-delete-button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  flex: 1;
  max-width: 150px;
}

.cancel-button {
  background: #6b7280;
  color: white;
}

.cancel-button:hover {
  background: #4b5563;
}

.confirm-delete-button {
  background: #ef4444;
  color: white;
}

.confirm-delete-button:hover {
  background: #dc2626;
}

.cancel-button:disabled,
.confirm-delete-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error {
  background: #fee2e2;
  color: #dc2626;
  padding: 12px;
  border-radius: 6px;
  text-align: center;
  margin: 20px 0;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #6b7280;
  font-size: 1.2rem;
}

.archive-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(251, 191, 36, 0.9);
  color: white;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
}

.event-card:hover .archive-button {
  opacity: 1;
}

.archive-button:hover {
  background: rgb(251, 191, 36);
  transform: scale(1.1);
}

.archive-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.archive-modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.archive-modal-content h2 {
  color: #1f2937;
  margin-bottom: 15px;
}

.archive-modal-content p {
  color: #6b7280;
  margin-bottom: 25px;
}

.archive-modal-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.cancel-button,
.confirm-archive-button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  flex: 1;
  max-width: 150px;
}

.confirm-archive-button {
  background: #f59e0b;
  color: white;
}

.confirm-archive-button:hover {
  background: #d97706;
}

.confirm-archive-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.event-timing-display {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: #666;
  margin: 4px 0;
}

.event-timing-display i {
  color: #f5a623;
}

.event-timing-display i.fa-flag-checkered {
  color: #28a745;
}

.event-timing-display i.fa-calendar {
  color: #666;
} 