.navigation {
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(8px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.nav-content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0.75rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
  color: inherit;
}

.navigation-logo__image {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.navigation-logo__text {
  font-weight: 600;
  font-size: 1.25rem;
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .navigation-logo__image {
    width: 40px;
    height: 40px;
  }
  
  .navigation-logo__text {
    font-size: 1.125rem;
  }
}

@media (max-width: 480px) {
  .navigation-logo__image {
    width: 36px;
    height: 36px;
  }
  
  .navigation-logo__text {
    font-size: 1rem;
  }
}

.nav-logo {
  font-size: 1.75rem;
  font-weight: 800;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: -0.01em;
}

.nav-logo::before {
  /* content: "🏃"; */
  font-size: 1.5rem;
  animation: run 1s infinite linear;
  display: inline-block;
}

@keyframes run {
  0% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0); }
}

.nav-logo:hover {
  color: #2563eb;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: #4b5563;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-size: 0.875rem;
}

.nav-link:hover {
  background-color: #f3f4f6;
  color: #3b82f6;
}

.sign-out-button {
  background: none;
  border: none;
  color: #4b5563;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: background-color 0.3s;
  font-size: 1rem;
}

.sign-out-button:hover {
  background-color: #fee2e2;
  color: #dc2626;
}

.user-profile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e7eb;
}

.profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile i {
  font-size: 1.5rem;
  color: #6b7280;
}

.login-button {
  background: #3b82f6;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.login-button:hover {
  background: #2563eb;
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.nav-links button i {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .nav-content {
    padding: 0.75rem 1rem;
  }

  .nav-links {
    gap: 0.5rem;
  }

  .nav-link span,
  .login-button span,
  .sign-out-button span {
    display: none;
  }

  .login-button,
  .sign-out-button,
  .nav-link {
    padding: 0.5rem;
  }

  .login-button i,
  .sign-out-button i,
  .nav-link i {
    margin: 0;
    font-size: 1.2rem;
  }

  .nav-logo {
    font-size: 1.5rem;
  }
  
  .nav-logo::before {
    font-size: 1.25rem;
  }
}

.profile-dropdown {
  position: relative;
  display: inline-block;
}

.profile-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 240px;
  margin-top: 10px;
  z-index: 1000;
  overflow: hidden;
}

.dropdown-header {
  padding: 15px;
  border-bottom: 1px solid #e5e7eb;
}

.user-name {
  color: #2c3e50;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.user-email {
  color: #6b7280;
  font-size: 0.875rem;
  margin: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 15px;
  color: #4b5563;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  font-size: 0.875rem;
}

.dropdown-item:hover {
  background-color: #f3f4f6;
}

.dropdown-item i {
  font-size: 1.1rem;
  width: 20px;
}

.profile-image-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-icon {
  font-size: 32px;
  color: #666;
}
.profile-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.admin-link {
  background-color: #fef3c7;
  color: #92400e;
}

.admin-link:hover {
  background-color: #fde68a;
  color: #92400e;
}

.admin-link i {
  color: #92400e;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.profile-image-container .profile-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e7eb;
}

.profile-image-container img[style*="display: none"] + .profile-icon {
  display: flex !important;
}

.navigation-logo__fallback {
  font-size: 2rem;
  color: #333; /* Adjust color to match your theme */
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
