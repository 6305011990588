.note-item {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
}

.note-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  flex: 1;
}

.author-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.anonymous-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
}

.author-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #334155;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.meta-info {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.75rem;
  color: #94a3b8;
  flex-shrink: 0;
}

.note-date {
  color: inherit;
  white-space: nowrap;
}

.delete-note-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.delete-note-button:hover {
  opacity: 1;
  color: #ef4444;
  background: rgba(239, 68, 68, 0.1);
}

/* Mobile optimizations */
@media (max-width: 640px) {
  .note-header {
    gap: 8px;
  }
  
  .author-info {
    gap: 6px;
  }

  .author-name {
    font-size: 0.8rem;
  }

  .delete-note-button {
    padding: 3px;
  }
  
  .delete-note-button i {
    font-size: 0.8rem;
  }
}

.note-content {
  margin-top: 8px;
  line-height: 1.5;
  color: #475569;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
} 