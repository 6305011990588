.event-photo {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 32px;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 16px;
  position: relative;
}

.event-photo::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 45%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 55%
  );
  pointer-events: none;
  animation: shimmer 4s infinite linear;
  z-index: 1;
}

.event-photo.archived::before {
  display: none;
}

@keyframes shimmer {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

.photo-container {
  position: relative;
  width: 100%;
  max-height: 800px;
  min-height: 400px;
  height: calc(100vh - 300px);
  border-radius: 12px;
  overflow: hidden;
  background: #f1f5f9;
}

.photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.photo::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 80%,
    rgba(255, 215, 0, 0.05) 85%,
    transparent 90%
  );
  pointer-events: none;
}

.photo:hover {
  transform: scale(1.02);
}

/* Placeholders */
.placeholder {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: #f1f5f9;
}

.placeholder.loading i {
  font-size: 2rem;
  color: #94a3b8;
  animation: pulse 1.5s infinite ease-in-out;
}

.placeholder.error {
  color: #ef4444;
}

.placeholder.error span {
  font-size: 0.875rem;
  color: #64748b;
}

/* Overlay */
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: linear-gradient(to top, rgba(0,0,0,0.6), transparent);
  pointer-events: none;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  font-size: 0.875rem;
  color: #1f2937;
  float: right;
}

/* Archived state */
.event-photo.archived .photo {
  filter: grayscale(30%);
}

/* Responsive adjustments */
@media (min-width: 1400px) {
  .photo-container {
    height: calc(100vh - 400px);
    max-height: 900px;
  }
}

@media (max-width: 1200px) {
  .photo-container {
    height: calc(100vh - 250px);
    max-height: 700px;
  }
}

@media (max-width: 992px) {
  .photo-container {
    height: calc(100vh - 200px);
    max-height: 600px;
  }
}

@media (max-width: 768px) {
  .event-photo {
    padding: 12px;
    border-radius: 12px;
  }
  
  .photo-container {
    height: calc(100vh - 150px);
    max-height: 500px;
    min-height: 300px;
    border-radius: 8px;
  }
}

@media (max-width: 480px) {
  .event-photo {
    padding: 8px;
    border-radius: 8px;
  }
  
  .photo-container {
    height: calc(100vh - 100px);
    max-height: 400px;
    min-height: 200px;
    border-radius: 6px;
  }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.1); opacity: 1; }
} 