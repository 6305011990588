.photo-upload-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.photo-header {
  text-align: center;
  margin-bottom: 30px;
  padding: 30px;
  background: linear-gradient(to right, #f8f9fa, #ffffff, #f8f9fa);
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.runner-name {
  color: #007bff;
  font-family: 'Playfair Display', serif;
  font-size: 2.2rem;
  display: block;
}

.header-title {
  font-size: 1.8rem;
  display: block;
  margin-top: 5px;
}

.header-subtitle {
  font-size: 1.1rem;
  color: #666;
  margin-top: 10px;
}

.upload-section {
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.hidden-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.upload-area {
  position: relative;
  border: 2px dashed #dee2e6;
  border-radius: 12px;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #f8f9fa;
  margin-bottom: 20px;
  
  &:focus-within {
    outline: 2px solid #007bff;
    outline-offset: 2px;
    border-color: #007bff;
  }
  
  &.drag-over {
    background: #e7f1ff;
    border-color: #007bff;
    transform: scale(1.01);
  }
}

.upload-area:focus-visible {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}

.upload-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: #495057;
  
  animation: fadeIn 0.3s ease;
}

.upload-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #e7f1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  
  i {
    font-size: 2.5rem;
    color: #007bff;
  }
}

.upload-prompt h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 10px;
}

.upload-instructions {
  color: #666;
  margin-bottom: 15px;
}

.file-limit {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  color: #666;
}

.image-preview {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-preview:hover .preview-overlay {
  opacity: 1;
}

.button-container {
  display: flex;
  gap: 15px;
  margin-top: 25px;
  justify-content: center;
}

.change-photo-button, .next-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 160px;
}

.change-photo-button {
  background: #f8f9fa;
  color: #444;
}

.next-button {
  background: #007bff;
  color: white;
}

.change-photo-button:hover, .next-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.helper-section {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.helper-text {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  margin: 10px 0;
  font-size: 0.9rem;
}

.helper-text i {
  color: #007bff;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #dc3545;
  background: #fff5f5;
  padding: 12px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
  }
  
  .change-photo-button, .next-button {
    width: 100%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
} 