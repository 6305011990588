/* Main Layout */
.admin-panel {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}

/* Tab Buttons */
.tab-buttons {
  margin: 20px 0;
  display: flex;
  gap: 10px;
}

.tab-buttons button {
  padding: 10px 20px;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
}

.tab-buttons button.active {
  background: #007bff;
  color: white;
}

/* Tables */
.events-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.events-table th,
.events-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Modals */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  min-width: 300px;
}

.modal-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Messages */
.success-message-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.success-message {
  background: #4caf50;
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
}

.error-message {
  background: #f44336;
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
  margin: 10px 0;
}

/* Stats Cards */
.stats-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.stat-card {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.stat-card h3 {
  margin: 0 0 10px 0;
  color: #666;
}

.stat-card p {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

/* Action Buttons */
.events-table button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 4px;
}

.events-table button.edit {
  background: #007bff;
  color: white;
}

.events-table button.delete {
  background: #dc3545;
  color: white;
}

.events-table button.visibility {
  background: #6c757d;
  color: white;
}

/* Add hover effects */
.events-table button:hover {
  opacity: 0.9;
}

.confirm-dialog {
  padding: 2rem;
  border-radius: 12px;
  border: none;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  width: 90%;
  
  /* Center positioning */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.confirm-dialog::backdrop {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
}

.confirm-dialog h2 {
  margin: 0 0 1rem;
  color: #1a1a1a;
  font-size: 1.5rem;
}

.confirm-dialog p {
  margin: 0 0 1.5rem;
  color: #666;
  line-height: 1.5;
}

.dialog-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.button-secondary {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.button-secondary:hover {
  background: #f5f5f5;
}

.button-danger {
  padding: 0.5rem 1rem;
  border: none;
  background: #dc3545;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.button-danger:hover {
  background: #c82333;
}

.catalog-section {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.section-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #edf2f7;
}

.empty-state {
  text-align: center;
  padding: 3rem 2rem;
  background: #f8fafc;
  border-radius: 12px;
  border: 1px dashed #e2e8f0;
  margin: 1.5rem 0;
}

.empty-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #4a5568;
  margin: 0 0 1rem;
}

.empty-state .helper-text {
  color: #718096;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.empty-state .helper-text i {
  color: #3182ce;
}

.catalog-content {
  margin-top: 1.5rem;
}

.table-container {
  overflow-x: auto;
  margin-top: 1rem;
}

.events-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.events-table th,
.events-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.events-table th {
  background: #f7fafc;
  font-weight: 600;
  color: #4a5568;
}

.events-table tr:hover {
  background: #f8fafc;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-button {
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.action-button.edit {
  background: #3182ce;
}

.action-button.delete {
  background: #e53e3e;
}

.action-button.share {
  background: #38a169;  /* Green color for share */
}

.action-button.share:hover {
  background: #2f855a;
}

.action-button:hover {
  opacity: 0.9;
}

.action-button i {
  font-size: 0.9rem;
}

.pagination-controls {
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f8f8f8;
}

.pagination-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
}

.pagination-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
}