.champion-frame {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.info-item i {
  font-size: 1.2rem;
  color: #666;
  width: 24px;
  text-align: center;
}

.info-details {
  display: flex;
  flex-direction: column;
}

.info-label {
  font-size: 0.875rem;
  color: #666;
}

.info-value {
  font-size: 1rem;
  color: #1a1a1a;
}

.info-value.highlight {
  font-size: 1.25rem;
  font-weight: 600;
  color: #007bff;
} 