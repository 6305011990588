.join-ambassador {
  text-align: center;
  padding: 6rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  border-radius: 16px;
  margin-top: 4rem;
}

.join-ambassador h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.join-ambassador p {
  color: #666;
  max-width: 600px;
  margin: 0 auto 2rem;
}

.cta-button {
  background: linear-gradient(to right, #3498db, #2980b9);
  color: white;
  padding: 1.2rem 2.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(52, 152, 219, 0.4);
}

.dialog {
  border: none;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

.dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.start-button.secondary {
  background: #6c757d;
}

.start-button.secondary:hover {
  background: #5a6268;
}

/* Inherit other styles from StartFlow.css */ 

.dialog.champion-frame::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
} 