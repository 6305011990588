.quick-notes {
  background: #f8fafc;
  border-radius: 12px;
  padding: 16px;
}

.quick-notes-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.quick-notes-header i {
  color: #eab308;
  font-size: 1rem;
}

.quick-notes-title {
  color: #334155;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
}

.quick-notes-subtitle {
  color: #64748b;
  font-size: 0.75rem;
  margin-left: auto;
}

.quick-notes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}

.quick-note-button {
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 6px;
  background: #f3f4f6;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  line-height: 1.4;
}

.quick-note-button:hover {
  background: #e5e7eb;
  color: #3b82f6;
}

@media (max-width: 768px) {
  .quick-notes {
    padding: 12px;
  }

  .quick-notes-header {
    margin-bottom: 12px;
    padding-bottom: 8px;
  }

  .quick-notes-subtitle {
    display: none; /* Hide subtitle on mobile to save space */
  }

  .quick-notes-grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    margin: 0.75rem 0;
  }

  .quick-note-button {
    padding: 0.625rem 0.875rem;
    font-size: 0.9rem;
  }
} 