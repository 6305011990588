.template-banner {
  background: linear-gradient(135deg, #fff8e1, #fffde7);
  border: 1px solid rgba(255, 193, 7, 0.2);
  border-radius: 12px;
  padding: 20px 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.template-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.template-icon {
  font-size: 24px;
  color: #ffc107;
  flex-shrink: 0;
}

.template-message {
  font-weight: 600;
  font-size: 1rem;
  color: #795548;
  flex-grow: 1;
}

.template-button {
  background-color: #ffc107;
  color: #795548;
  font-weight: 600;
  font-size: 0.9375rem;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  flex-shrink: 0;
}

.template-button:hover {
  background-color: #ffb300;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(255, 193, 7, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .template-banner {
    padding: 16px 20px;
  }

  .template-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
  }

  .template-button {
    width: 100%;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .template-banner {
    padding: 16px;
  }

  .template-icon {
    font-size: 20px;
  }

  .template-message {
    font-size: 0.9375rem;
  }

  .template-button {
    font-size: 0.875rem;
    padding: 10px 16px;
  }
} 