.promo-footer {
  width: 100%;
  padding: 64px 24px;
  background: #fafafa;
  margin-top: 48px;
}

.promo-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.promo-header {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.promo-header i {
  color: #3b82f6;
  font-size: 1.75rem;
}

.promo-header h3 {
  color: #1f2937;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
  letter-spacing: -0.01em;
}

.promo-description {
  margin-bottom: 40px;
}

.promo-description p {
  color: #4b5563;
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 600px;
}

.promo-actions {
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: 0 16px;
}

.promo-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.15s ease;
  text-decoration: none;
}

.promo-button.primary {
  background: #3b82f6;
  color: white;
}

.promo-button.primary:hover {
  background: #2563eb;
}

.promo-button.secondary {
  background: white;
  color: #3b82f6;
  border: 1px solid #e5e7eb;
}

.promo-button.secondary:hover {
  border-color: #3b82f6;
  background: #f8fafc;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .promo-footer {
    padding: 48px 16px;
    margin-top: 32px;
  }

  .promo-header h3 {
    font-size: 1.5rem;
  }

  .promo-description p {
    font-size: 1rem;
  }

  .promo-actions {
    flex-direction: column;
    gap: 12px;
  }

  .promo-button {
    width: 100%;
    justify-content: center;
  }
} 