.event-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.event-header {
  text-align: center;
  margin-bottom: 25px;
  padding: 25px;
  background: linear-gradient(to right, #f8f9fa, #ffffff, #f8f9fa);
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.event-header h1 {
  margin-bottom: 15px;
  color: #1a1a1a;
}

.runner-name {
  color: #007bff;
  font-family: 'Playfair Display', serif;
  font-size: 2.2rem;
  display: block;
}

.header-title {
  font-size: 1.8rem;
  display: block;
  margin-top: 5px;
}

.header-subtitle {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

.auth-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: #f8f9fa;
  padding: 12px 20px;
  border-radius: 8px;
  margin-top: 15px;
}

.auth-banner i {
  font-size: 1.25rem;
  color: #94a3b8;
}

.event-form-container {
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.form-section {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.section-title {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 2px solid #007bff;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: #444;
  font-weight: 500;
}

.form-group label i {
  color: #94a3b8;
  font-size: 0.875rem;
  margin-right: 6px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.1);
}

.optional-text {
  color: #94a3b8;
  font-size: 0.75rem;
  margin-left: 4px;
  font-weight: normal;
}

.helper-text {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: #64748b;
  font-size: 0.75rem;
  line-height: 1.4;
  margin-top: 8px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 8px 12px;
}

.helper-text i {
  color: #94a3b8;
  font-size: 0.75rem;
  margin-top: 2px;
  flex-shrink: 0;
}

.form-group input:focus + .helper-text {
  color: #007bff;
  transition: color 0.2s ease;
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 25px;
  justify-content: center;
  padding: 0 20px;
}

.review-button, .next-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  min-width: 160px;
}

.review-button {
  background: #f8f9fa;
  color: #444;
}

.next-button {
  background: #007bff;
  color: white;
  margin-left: auto;
}

.review-button:hover, .next-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.review-button:disabled, .next-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.review-button i, .next-button i {
  font-size: 1rem;
  color: inherit;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #dc3545;
  background: #fff5f5;
  padding: 12px 20px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}

.error-message i {
  font-size: 1.1rem;
  color: #94a3b8;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .form-row {
    grid-template-columns: 1fr;
  }
  
  .button-group {
    flex-direction: column;
  }
  
  .next-button {
    margin-left: 0;
  }
}

.review-dialog {
  border: none;
  border-radius: 15px;
  padding: 0;
  max-width: 500px;
  width: 90%;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  /* Center the dialog */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.review-dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.review-content {
  padding: 25px;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.review-header h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.review-header .close-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 5px;
  font-size: 1.2rem;
}

.review-section {
  margin-bottom: 25px;
}

.review-section h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #007bff;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.review-section p {
  margin: 10px 0;
  padding-left: 25px;
  color: #444;
}

.review-section strong {
  color: #333;
  margin-right: 8px;
}

.review-footer {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

.review-footer .close-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 180px;  /* Ensure minimum button width */
}

.review-footer .close-button:hover {
  background: #218838;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Ensure dialog fits on mobile */
@media (max-width: 768px) {
  .review-dialog {
    width: 95%;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .review-footer .close-button {
    width: 100%;  /* Full width on mobile */
    min-width: unset;
  }
} 