.error-404 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f8fafc;
}

.error-content {
  text-align: center;
  max-width: 400px;
}

.error-404 h1 {
  font-size: 6rem;
  font-weight: 700;
  color: #3b82f6;
  line-height: 1;
  margin: 0;
}

.error-404 h2 {
  font-size: 2rem;
  color: #1f2937;
  margin: 1rem 0;
}

.error-404 p {
  color: #6b7280;
  margin-bottom: 2rem;
}

.home-button {
  display: inline-block;
  background-color: #3b82f6;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

.home-button:hover {
  background-color: #2563eb;
} 