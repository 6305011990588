.event-info {
  width: 100%;
  padding: 16px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 16px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.info-item i {
  font-size: 1.25rem;
  width: 24px;
  text-align: center;
}

.info-item .fa-running { color: #4CAF50; }
.info-item .fa-map-marker-alt { color: #F44336; }
.info-item .fa-calendar-alt { color: #FF9800; }
.info-item .fa-clock { color: #9C27B0; }

.info-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0; /* Prevents text overflow */
}

.info-label {
  font-size: 0.75rem;
  font-weight: 600;
  color: #6c757d;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.info-value {
  font-size: 0.9375rem;
  color: #2c3e50;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-value.highlight {
  color: #4a90e2;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .event-info {
    padding: 12px;
  }
  
  .info-grid {
    gap: 12px;
  }
  
  .info-item {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .event-info {
    padding: 8px;
  }
  
  .info-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  
  .info-item {
    padding: 12px;
  }
} 