.actions-container {
  margin: 24px 0;
  padding: 24px 0;
  border-bottom: 1px solid #e5e7eb;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 8px 0;
}

.action-button-wrapper {
  position: relative;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  background: white;
  color: #4b5563;
  transition: all 0.15s ease;
  cursor: pointer;
}

.action-button.share-button {
  background: #2563eb;
  color: white;
}

.action-button.template {
  background: #4b5563;
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.action-button i {
  font-size: 1.25rem;
}

/* Tooltip styles */
.action-button-wrapper::after {
  content: attr(title);
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 12px;
  background: #1f2937;
  color: white;
  font-size: 0.875rem;
  border-radius: 6px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
}

.action-button-wrapper:hover::after {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 480px) {
  .action-buttons {
    padding: 16px 0;
  }
  
  .action-button {
    width: 44px;
    height: 44px;
  }
} 