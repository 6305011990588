:root {
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.landing-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
}

.hero {
  position: relative;
  padding: 80px 20px;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('../../assets/images/hero.webp') center/cover;
  background-repeat: no-repeat;
  color: white;
  border-radius: 10px;
  margin-bottom: 40px;
  content-visibility: auto;
  contain-intrinsic-size: 200px;
  font-family: var(--font-primary);
}

.hero h1 {
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: -0.025em;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.hero .tagline {
  font-size: 1.5rem;
  font-weight: 400;
  color: #f3f4f6;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
  letter-spacing: -0.01em;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 60px 40px;
  background-color: #f8f9fa;
  margin-top: 40px;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card i {
  font-size: 2.5rem;
  color: #3498db;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.4rem;
  color: #2c3e50;
  margin-bottom: 12px;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.signup-section {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.signup-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.signup-form h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #1f2937;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 1rem;
}

.signup-button {
  width: 100%;
  padding: 12px;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-button:hover {
  background: #2563eb;
}

@media (max-width: 768px) {
  .hero {
    padding: 60px 16px;
  }

  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .hero .tagline {
    font-size: 1.25rem;
    padding: 0 1rem;
  }

  .signup-form {
    margin: 0 20px;
    padding: 30px 20px;
  }

  .features {
    padding: 40px 20px;
  }

  .landing-container {
    padding: 0 1rem;
  }
}

.visually-loaded {
  display: block;
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dark mode variant */
@media (prefers-color-scheme: dark) {
  .visually-loaded {
    background: linear-gradient(
      120deg,
      #c7d2fe 0%,
      #e0e7ff 25%,
      #f1f5f9 50%,
      #e0e7ff 75%,
      #c7d2fe 100%
    ) no-repeat;
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

/* Respect reduced motion preferences */
@media (prefers-reduced-motion: reduce) {
  .visually-loaded {
    animation: none;
    background-size: 100% 100%;
  }
}

.faq-section {
  padding: 60px 40px;
  background-color: white;
}

.faq-section h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 40px;
  font-size: 2rem;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
}

.faq-question h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #2c3e50;
}

.faq-icon {
  font-size: 1.5rem;
  color: #3498db;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-item.active .faq-answer {
  max-height: 200px;
  padding: 20px;
  background-color: white;
}

.faq-answer p {
  margin: 0;
  line-height: 1.6;
  color: #666;
}

@media (max-width: 768px) {
  .faq-section {
    padding: 40px 20px;
  }
}

/* Add preload meta tag */
@media (min-width: 768px) {
  .hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                      url('../../assets/images/hero.webp');
  }
}

@media (max-width: 767px) {
  .hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                      url('../../assets/images/hero.webp');
  }
} 