.banner-container {
  margin: 32px 0;
  padding: 0 32px;
}

.guest-banner {
  background: linear-gradient(to right, #f0f9ff, #e0f2fe);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  border: 1px solid #bae6fd;
}

.guest-banner i {
  font-size: 32px;
  color: #2196F3;
  flex-shrink: 0;
}

.guest-message {
  flex-grow: 1;
  min-width: 0; /* Prevents text overflow */
}

.guest-message h3 {
  color: #0369a1;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 12px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.guest-message p {
  color: #0c4a6e;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 16px 0;
}

.emoji {
  font-size: 1.4em;
  display: inline-block;
  vertical-align: middle;
}

.guest-cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  font-weight: 600;
  font-size: 0.9375rem;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.2s ease;
  white-space: nowrap;
  flex-shrink: 0;
}

.guest-cta:hover {
  background-color: #1976D2;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(33, 150, 243, 0.2);
}

.guest-actions {
  display: flex;
  justify-content: flex-start;
}

.sign-in-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background: #0284c7;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.sign-in-button:hover {
  background: #0369a1;
  transform: translateY(-1px);
}

/* Responsive styles */
@media (max-width: 768px) {
  .guest-banner {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 16px;
  }

  .guest-message {
    width: 100%;
  }

  .guest-cta {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  .guest-banner {
    padding: 16px;
  }

  .guest-message h3 {
    font-size: 1.125rem;
  }

  .guest-message p {
    font-size: 0.875rem;
  }
} 