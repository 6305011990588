.signin-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%);
}

.signin-container {
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.signin-container h1 {
  color: #2d3748;
  font-size: 1.875rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.signin-message {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: #ebf8ff;
  color: #2b6cb0;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  text-align: left;
}

.signin-message i {
  font-size: 1.25rem;
  flex-shrink: 0;
}

.signin-options {
  margin: 2rem 0;
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0.75rem 1.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #4a5568;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.google-signin-button:hover {
  background: #f8fafc;
  border-color: #cbd5e0;
  transform: translateY(-1px);
}

.google-signin-button img {
  width: 24px;
  height: 24px;
}

.signin-footer {
  margin-top: 2rem;
  color: #718096;
  font-size: 0.875rem;
}

.signin-footer a {
  color: #4299e1;
  text-decoration: none;
}

.signin-footer a:hover {
  text-decoration: underline;
}

@media (max-width: 640px) {
  .signin-container {
    padding: 1.5rem;
  }
  
  .signin-container h1 {
    font-size: 1.5rem;
  }
} 