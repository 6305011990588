#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

/* Add any additional global styles here */
